import { gql } from '@apollo/client'
import { TripFragments } from './TripFragments'

export const TripQueries = {
  GET_ALL_TRIPS: gql`
    query GetAllTrips {
      userMe {
        id
        trips {
          id
          ...TripListDataFragment
        }
        sharedTrips {
          id
          ...TripListDataFragment
        }
        memberTrips {
          id
          data {
            name
            dateFrom
            dateTo
            membersCount
            venuesCount
            thumbnail
            token
          }
        }
      }
    }
    ${TripFragments.TRIP_LIST_DATA_FRAGMENT}
  `,

  GET_TRIP_DETAIL: gql`
    query TripDetail($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        shareToken
        data {
          name
          userId
          logoUrl
          heroUrl
          contactName
          contactEmail
          externalLink1
          dateFrom
          dateTo
          lat
          lon
          createdAt

          deadlineNotifications
          changeNotifications
          defaultEmails
        }

        destinations {
          id
          destinationId
        }

        teamLocations {
          id
          ...TeamLocationFragment
        }

        todos {
          id
          isDone
        }

        tripVenues {
          id
          isSelected
          venue {
            id
            ...TripVenueFragment
          }
          prices {
            id
            from
            to
            pax
            isAvailable
            externalLink
            presentationLink
            workspaceFrom
            workspaceTo
            foodFrom
            foodTo
            transportFrom
            transportTo
            activitiesFrom
            activitiesTo
            serviceFeeFrom
            serviceFeeTo
            note
            roomsCount
            rooms
            price {
              amount
              currencyIsoCode
            }
          }
        }

        itineraryEntries {
          id
          ...ItineraryFragment
        }

        activities {
          id
          ...ActivityFragment
        }

        deadlines {
          id
          type
          date
        }
      }
    }
    ${TripFragments.TEAM_LOCATION}
    ${TripFragments.ITINERARY}
    ${TripFragments.ACTIVITY_FRAGMENT}
    ${TripFragments.TRIP_FRAGMENT}
  `,

  GET_TRIP_ROOMING_LIST: gql`
    query GetTripRoomingList($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        sleepingLayoutsGroups {
          id
          ...SleepingLayoutGroupFragment
        }
      }
    }
    ${TripFragments.SLEEPING_LAYOUT_GROUP_FRAGMENT}
  `,

  GET_TRIP_TRANSFER_GROUPS: gql`
    query GetTripTransferGroups($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        transferGroups {
          id
          ...TransferGroupFragment
        }
      }
    }
    ${TripFragments.TRANSFER_GROUP_FRAGMENT}
  `,

  GET_TRIP_VOTINGS: gql`
    query GetTripVotings($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        votings {
          id
          ...VotingFragment
        }
      }
    }
    ${TripFragments.VOTING_FRAGMENT}
  `,

  GET_TRIP_REQUIREMENTS: gql`
    query GetTripRequirements($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        requirements {
          ...TripRequirementsFragment
        }
      }
    }
    ${TripFragments.TRIP_REQUIREMENTS_FRAGMENT}
  `,

  GET_TRIP_REQUIREMENTS_FOR_RECOMMENDATION: gql`
    query GetTripRequirementsForReccommendation($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        requirements {
          team {
            teamSize
          }
          nights {
            nightCount
          }
          destination {
            destination
            fromList
          }
          budget {
            minimumBudget
            maximumBudget
          }
        }
      }
    }
  `,

  GET_DESTINATIONS_FOR_TRIP_REQUIREMENTS: gql`
    query AvailableTripRequirementsDestinations {
      availableDestinations {
        id
        title
        country
      }
    }
  `,

  GET_SHARED_TRIP_DETAIL: gql`
    query SharedTripDetail($token: String!) {
      sharedTrip(token: $token) {
        id
        shareToken
        data {
          name
          userId
          heroUrl
        }
        tripVenues {
          id
          venue {
            id
            ...TripVenueFragment
          }
        }
      }
    }
    ${TripFragments.TRIP_FRAGMENT}
  `,

  GET_TRIP_TODOS: gql`
    query TripTodos($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        todos {
          id
          ...TodoFragment
        }
      }
    }
    ${TripFragments.TODO_FRAGMENT}
  `,

  GET_DESTINATION_FOR_TRIP: gql`
    query AvailableDestinations {
      availableDestinations {
        id
        title
        country
        countryCode
        pictureUrl
      }
    }
  `,

  GET_ITINERARY_CALENDAR_URL: gql`
    query Query($tripId: Int!) {
      getItineraryCalendarUrl(tripId: $tripId)
    }
  `,

  GET_SHARED_USERS: gql`
    query TripSharedUsers($tripId: Int!) {
      tripSharedUsers(tripId: $tripId) {
        id
        userEmail
        summary
        todos
        venues
        tripRequirements
        dates
        itinerary
        activities
        voting
        team
        settings
        expenses
        transfer
        sleepingLayout
        reviews
        contacts
        questions
        level

        userData {
          avatar
        }
      }
    }
  `,

  GET_ATTENDANCE_INFO: gql`
    query GetAttendanceInfo($memberKey: String!) {
      getAttendanceInfo(memberKey: $memberKey) {
        token
        status
        name
        email
        avatarUrl
        attendancePageOpenedAt

        birthDate
        documentId
        documentType
        validUntil
        phoneNumber
        address

        sleepingLayoutMembers {
          name
          avatarUrl
        }

        transferGroups {
          name
          note       
          isArrival
          firstDate
          lastDate
          members {
            name
            avatarUrl
          }
        }

        venue {
          id
          data {
            thumbnailUrl
            thumbnailUrls(limit: 100)
            roomPhotos
            title
            countryCode
            country
            city
            type
            rating
            meetingSpaceSize
            price {
              amount
              currencyIsoCode
            }
            coordinates {
              lat
              lon
            }
            venueDestination {
              id
              airports {
                name
                distanceInKilometers
                code
                coordinates {
                  lat
                  lon
                }
              }
            }
            houseRules {
              checkIn
              checkOut
              cancellationDescription
              roomTypeAssignmentDescription
            }
            address
            amenities {
              amenityGroups {
                amenities
                name
                id
              }
              featured {
                name
                photoUrl
              }
            }
            starRating
            description
            numberOfRooms
            isMeetingRoomIncluded
            isEntireHouse
            isSustainable
            location {
              distanceToCoast
              download
              upload
              sightseeing
              restaurants
              nightlife
            }
            hotelDetails {
              importantInformation
            }
            capacity
            pointsOfInterests(limit: 100) {
              id
              name
              lat
              lon
              distance
              categoryId
              categoryName
            }
            carbonEmissions {
              room
            }
            staticData {
              meetingRooms {
                id
                roomSquareFootage
                roomSquareMeters
              }
            }
          }
        }

        questions {
          ...TripQuestionFragment
        }

        transport {
          id
          ...TeamMemberTransportFragment
        }
        votings {
          id
          name
          type
          isActive
          isSingleSelect
          options {
            id
            title
            description
            photoUrl
            votes {
              id
              isSelf
            }
          }
        }
        trip {
          name
          heroUrl
          logoUrl
          dateFrom
          dateTo
          selectedVenue {
            destination {
              title
            }
          }
          reviewAnonymized
          shareToken
        }
        preferences {
          food
          sleeping
          other
        }
        ...OriginLocationFragment

        reviews {
          id
          name
          description
          value {
            id
            value
          }
        }
        deadlines {
          id
          type
          date
        }
        itineraryEntries {
          id
          ...ItineraryFragment
        }
        settings {
          id
          ...AttendancePageSettingsFragment
        }
      }
    }
    ${TripFragments.ITINERARY}
    ${TripFragments.ORIGIN_LOCATION_FRAGMENT}
    ${TripFragments.TEAM_MEMBER_TRANSPORT}
    ${TripFragments.TRIP_QUESTION_FRAGMENT}
    ${TripFragments.ATTENDANCE_PAGE_SETTINGS_FRAGMENT}
  `,

  GET_ACCESS_LEVEL: gql`
    query tripAccess($tripId: Int!) {
      tripAccess(tripId: $tripId) {
        summary
        todos
        venues
        tripRequirements
        isActive
        dates
        team
        itinerary
        activities
        voting
        settings
        expenses
        transfer
        sleepingLayout
        reviews
        contacts
        questions
        level
      }
    }
  `,

  PARSE_ACTIVITY: gql`
    query ParseActivity($url: String!) {
      parseActivity(url: $url) {
        id
        ...ActivityFragment
      }
    }
    ${TripFragments.ACTIVITY_FRAGMENT}
  `,

  BROWSE_ACTIVITIES: gql`
    query BrowseActivities($lat: Float!, $lon: Float!) {
      browseActivities(lat: $lat, lon: $lon) {
        id
        ...ActivityFragment
      }
    }
    ${TripFragments.ACTIVITY_FRAGMENT}
  `,

  SEARCH_AIRPORTS: gql`
    query SearchAirports($search: String!) {
      searchAirports(search: $search) {
        id
        name
        country
        municipality
      }
    }
  `,

  GET_TRIP_EXPENSES: gql`
    query TripExpenses($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        tripExpenses {
          id
          ...ExpenseFragment
        }
      }
    }
    ${TripFragments.EXPENSE_FRAGMENT}
  `,

  GET_TRIP_REVIEWS: gql`
    query TripReviews($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        reviewGroups {
          id
          ...ReviewGroupFragment
        }
        feedbacks {
          id
          member {
            id
            name
          }
          value

          createdAt
          editedAt
          editedBy
        }
      }
    }
    ${TripFragments.REVIEW_GROUP_FRAGMENT}
  `,

  GET_TRIP_FEED: gql`
    query Feed($tripId: Int!, $from: Int, $count: Int) {
      trip(tripId: $tripId) {
        id
        feed(from: $from, count: $count) {
          id
          event
          group
          metaData
          user
          createdAt
        }
      }
    }
  `,

  VENUE_RECOMMENDATIONS: gql`
    query VenueRecommendations($input: VenueRecommendationInput!) {
      venueRecommendations(input: $input) {
        id
        title
        thumbnailUrl
        thumbnailUrls
        destinationId
        reviewRating
        city
        country
        countryCode
      }
    }
  `,

  GET_TRIP_EMISSIONS: gql`
    query TripEmissions($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        emissions {
          type
          amountKg
        }
      }
    }
  `,

  GET_TRIP_REQUIREMENTS_FOR_LINK: gql`
    query GetTripRequirementsForLink($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        requirements {
          team {
            teamSize
          }
          destination {
            destination
            fromList
          }
          workingSpace {
            workingSpace
          }
          accommodation {
            accommodationType
          }
        }
      }
    }
  `,

  GET_TRIP_VENUES: gql`
    query TripVenues {
      tripVenues {
        id
        name
        heroUrl
        venues
      }
    }
  `,

  GET_SHARED_USERS_FOR_TODOS: gql`
    query TripSharedUsersForTodos($tripId: Int!) {
      tripSharedUsers(tripId: $tripId) {
        id
        userEmail
        todos
        level
      }
    }
  `,

  GET_ATTENDANCE_SETTINGS: gql`
    query GetAttendanceSettings($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        attendancePageSettings {
          id
          ...AttendancePageSettingsFragment
        }
      }
    }
    ${TripFragments.ATTENDANCE_PAGE_SETTINGS_FRAGMENT}
  `,

  GET_TRIP_CONTACTS: gql`
    query GetTripContacts($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        contacts {
          id
          ...TripContactsFragment
        }
      }
    }
    ${TripFragments.TRIP_CONTACTS_FRAGMENT}
  `,

  GET_TRIP_QUESTIONS: gql`
    query GetTripQuestions($tripId: Int!) {
      trip(tripId: $tripId) {
        id
        questions {
          id
          ...TripQuestionsFragment
        }
      }
    }
    ${TripFragments.TRIP_QUESTIONS_FRAGMENT}
  `,

  SEARCH_VENUES: gql`
    query SearchVenues($search: String!) {
      searchVenues(search: $search) {
        id
        name
        destination
        type
        country
        city
        thumbnail
        address
      }
    }
  `,
}
